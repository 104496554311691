<template>
  <div>
    <header class="gw-wid bg-white gw-header-box flex-left">
      <div class="gw-wid-12 flex-center">
        <div class="logo">
          <img src="@/assets/img/red.png" />
        </div>
        <div class="gw-navs" v-if="isMenu">
          <ul class="flex-center">
            <li
              v-for="(item, index) in menus"
              :key="index"
              @click="jumpMap(item)"
              :class="{ hover: item.type === menusIndex }"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="gw-menu-h5" @click="isMenu = !isMenu"></div>
      </div>
    </header>
    <!-- banner -->
    <section class="gw-wid gw-banner home">
      <div class="gw-wid-12">
        <h2>一站式综合API服务商</h2>
        <div class="slogin">
          <span class="fa fa-thumbs-up"></span>
          <div class="main">
            <h3>顶尖团队</h3>
            <p>
              专注服务、注重安全、体验流畅 API集成综合供应商
              <a href="https://t.me/kmbw_Group"  target="_blank">------联系我们</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- 提供什么 -->
    <section class="gw-wid introduce">
      <div class="gw-wid-12">
<!--        <div class="gw-title">-->
<!--          <h3><span>GM</span> 提供什么？</h3>-->
<!--          <hr class="line" />-->
<!--          <p>GM提供web端、移动端、后台管理,整套包网API</p>-->
<!--        </div>-->
        <div class="gw-why">
          <img src="@/assets/img/trio.png" class="img" />
          <div class="dian one">
            <span class="fa fa-gift"></span>
            <p>移动端</p>
          </div>
          <div class="dian two">
            <span class="fa fa-mobile"></span>
            <p>H5</p>
          </div>
          <div class="dian three">
            <span class="fa fa-code"></span>
            <p>web端</p>
          </div>
        </div>
      </div>
      <div class="gw-wid bg-gray">
        <div class="gw-wid-12">
          <ul class="gw-why-ul">
            <li v-for="(item, index) in gmWhys" :key="index">
              <span class="fa" :class="item.icon"></span>
              <h3>{{ item.name }}</h3>
              <p>{{ item.sec }}</p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- 全球顶尖研发团队 -->
    <section class="gw-wid gw-team gw-flash-bg">
      <h3>全球顶尖研发团队</h3>
      <h4>8年成熟经验为您服务</h4>
      <p>各大公司核心IT精英</p>
    </section>

    <!-- 产品优势 -->
    <section class="gw-wid superior">
      <div class="gw-wid-12">
        <div class="gw-title">
          <h3>产品优势</h3>
          <hr class="line" />
          <p>
            惊艳的产品解决方案：以用户体验为中心，以技术为核心，以服务为宗旨
          </p>
        </div>
        <ul class="gw-superior">
          <li v-for="(item, index) in superior" :key="index">
            <img :src="item.img" class="img" />
            <h3>{{ item.name }}</h3>
            <strong>{{ item.en }}</strong>
            <p>{{ item.sec }}</p>
          </li>
        </ul>
      </div>
    </section>

    <!-- 产品介绍 -->
    <section class="gw-wid bg-gray produce">
<!--      <div class="gw-wid-12">-->
<!--        <div class="gw-title">-->
<!--          <h3><span>产品</span> 介绍</h3>-->
<!--          <hr class="line" />-->
<!--        </div>-->
<!--      </div>-->
      <ul class="gw-icons">
        <li v-for="(item, index) in produces" :key="index">
          <img
            :src="require('@/assets/img/produce/' + (index + 1) + '.jpg')"
            class="img"
          />
          <div class="box">
            <span>{{ item.name }}</span>
            <p>{{ item.hy }}</p>
            <strong class="link" @click="tabLogo(item, index)">查看</strong>
          </div>
        </li>
      </ul>
    </section>

    <!-- 我们合作 -->
    <section class="gw-wid cooperate">
      <div class="gw-wid-12">
        <div class="gw-title">
          <h3><span>如何与</span> 我们合作</h3>
          <hr class="line" />
          <p>
            请联系客服提交你的合作意向，招商经理将您的运营资质进行考核后于您签署合约，合约生效后将建立商务、财务、运营通道
          </p>
        </div>
        <div class="gw-parner">
          <img src="@/assets/img/choose-us.png" class="img" />
          <div class="right">
            <dl v-for="(item, index) in parents" :key="index">
              <dd
                @click="parentIndex = index"
                :class="parentIndex === index ? 'hover' : ''"
              >
                <span class="icon"></span>
                <span class="name">{{ item.name }}</span>
              </dd>
              <dt v-if="parentIndex === index" v-html="item.sec"></dt>
            </dl>
          </div>
        </div>
      </div>
    </section>

    <!-- 联系我们 -->
<!--    <section class="gw-wid gw-contact-bg">-->
<!--      <div class="gw-wid-12">-->
<!--        <div class="gw-contact">-->
<!--          <div class="left">-->
<!--            <h3>期待您的合作</h3>-->
<!--            <span>选择大于努力&选择我们让您事半功倍</span>-->
<!--          </div>-->
<!--          <div class="right">联系我们</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <!-- 为什么选择我们 -->
    <section class="gw-wid gw-select gw-flash-bg">
<!--      <div class="h3">为什么选择我们GM</div>-->
      <div class="gw-wid-12">
        <ul class="gw-select-ul">
          <li v-for="(item, index) in selects" :key="index">
            <span
              >{{ item.number }} <span>{{ item.unit }}</span></span
            >
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>
    </section>

    <!-- 底部logo -->
<!--    <section class="gw-wid">-->
<!--      <div class="gw-wid-12">-->
<!--        <ul class="flex-center gw-footer-logo">-->
<!--          <li>-->
<!--            <img src="@/assets/img/cisco.png" />-->
<!--          </li>-->
<!--          <li>-->
<!--            <img src="@/assets/img/walmart.png" />-->
<!--          </li>-->
<!--          <li>-->
<!--            <img src="@/assets/img/gamescast.png" />-->
<!--          </li>-->
<!--          <li>-->
<!--            <img src="@/assets/img/spinwokrx.png" />-->
<!--          </li>-->
<!--          <li>-->
<!--            <img src="@/assets/img/ngreen.png" />-->
<!--          </li>-->
<!--          <li>-->
<!--            <img src="@/assets/img/vimeo.png" />-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </section>-->

    <!-- footer -->
    <section class="gw-wid gw-footer">
      <div class="gw-wid-12 gw-footer-flex">
        <div class="left">
          <h3>
            客户至上是我们的唯一追求的目标,只要你有要求,我们一定尽最快的速度,最专业的角度去实现客户目标。合作共赢才是赢!
          </h3>
<!--          <p>© 2024 GM Gaming | All rights reserved.</p>-->
        </div>
        <div class="center">一站式综合API服务商</div>
        <div class="right">
          <strong>联系方式</strong>
          <p>
            Telegram:
            <a href="https://t.me/kmbw_Group" class="text-blue">@kmbw_Group</a>
            <br />
          </p>
        </div>
      </div>
    </section>
    <!-- 图片 -->
    <div class="gw-view-img" v-if="gwShow" @click="closeViews">
      <div class="gw-view-box" @click.stop="gwShow = true">
        <img :src="viewImgs.img" />
        <h3>{{ viewImgs.name }}</h3>
        <p>{{ viewImgs.hy }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      gwShow: false,
      viewImgs: null,
      isMenu: true,
      menusIndex: "home",
      menus: [
        {
          name: "首页",
          link: "",
          type: "home",
        },
        {
          name: "介绍",
          link: "",
          type: "introduce",
        },
        {
          name: "优势",
          link: "",
          type: "superior",
        },
        {
          name: "产品",
          link: "",
          type: "produce",
        },
        {
          name: "合作",
          link: "",
          type: "cooperate",
        },

      ],
      gmWhys: [
        {
          name: "基础产品",
          icon: "fa-heart",
          sec: "基础产品包括Android、ios、web,集成了市面上最全面的游戏场馆",
        },
        {
          name: "综合后台",
          icon: "fa-mobile",
          sec: "提供最全面、最便捷、体验最好的后台管理,让您轻松管理",
        },
        {
          name: "财务整合",
          icon: "fa-signal",
          sec: "集成多家安全通道,方便快捷的交易系统",
        },
        {
          name: "客服服务",
          icon: "fa-camera",
          sec: "专业客服团队7*24小时提供答疑,及时解决用户遇到的问题",
        },
      ],
      superior: [
        {
          img: require("@/assets/img/img6-large.jpg"),
          name: "支付优势",
          en: "Payment advantages",
          sec: "克服重重困难，整合线上多种资源，支付领域全覆盖",
        },

        {
          img: require("@/assets/img/img7-large.jpg"),
          name: "技术优势",
          en: "Technological advantages",
          sec: "以技术为核心的研发型团队，满足用户各种技术上的支持",
        },

        {
          img: require("@/assets/img/img8-large.jpg"),
          name: "成本优势",
          en: "Cost advantage",
          sec: "搭建成本，运营成本极低,所有产管全部免费",
        },
      ],
      parents: [
        {
          name: "商务资讯",
          sec: `找到官方客服<a href="https://t.me/kmbw_Group" class="text-blue">点击此处</a>，将您的想法和要求告知我们`,
        },
        {
          name: "签署合约",
          sec: `我们将与您签署意向合约，保证您的权益`,
        },
        {
          name: "品牌策划",
          sec: `我们将根据您的要求,给您做出一整套的品牌策划,包括整套UI和VI设计,让您省心放心`,
        },
        {
          name: "网站上线",
          sec: `整套方案搭建完成并与您确认完成测试并上线`,
        },
        {
          name: "投入运营",
          sec: `产品上线并不是服务的终点,投入运营后,我们依然24小时在线,随时为您保驾护航`,
        },
      ],
      parentIndex: 0,
      selects: [
        {
          name: "游戏产品",
          number: "100",
          unit: "+",
        },
        {
          name: "服务客户",
          number: "1000",
          unit: "+",
        },
        {
          name: "研发团队",
          number: "100",
          unit: "+",
        },
        {
          name: "服务宗旨",
          number: "24",
          unit: "h",
        },
      ],
      produces: [
        // {
        //   name: "SEXY",
        //   hy: "行业知名",
        //   img: require("@/assets/img/produce/1.jpg"),
        // },
        // {
        //   name: "DB",
        //   hy: "全覆盖大平台",
        //   img: require("@/assets/img/produce/2.jpg"),
        // },
        // {
        //   name: "开元",
        //   hy: "老牌公司",
        //   img: require("@/assets/img/produce/3.jpg"),
        // },
        // {
        //   name: "大游",
        //   hy: "优质丰富产品",
        //   img: require("@/assets/img/produce/4.jpg"),
        // },
        // {
        //   name: "MG",
        //   hy: "标杆品牌",
        //   img: require("@/assets/img/produce/5.jpg"),
        // },
        // {
        //   name: "CQ9",
        //   hy: "产品丰富品种繁多",
        //   img: require("@/assets/img/produce/6.jpg"),
        // },
        // {
        //   name: "熊猫",
        //   hy: "多宝旗下顶尖",
        //   img: require("@/assets/img/produce/7.jpg"),
        // },
        // {
        //   name: "沙巴",
        //   hy: "行业领先,赛事最全",
        //   img: require("@/assets/img/produce/8.jpg"),
        // },
        // {
        //   name: "SBO",
        //   hy: "全球各项赛事",
        //   img: require("@/assets/img/produce/9.jpg"),
        // },
        // {
        //   name: "三昇",
        //   hy: "成立于2008年,老牌平台",
        //   img: require("@/assets/img/produce/10.jpg"),
        // },
        // {
        //   name: "CMD",
        //   hy: "CMD信誉佳",
        //   img: require("@/assets/img/produce/11.jpg"),
        // },
        // {
        //   name: "雷火",
        //   hy: "行业领先电竞平台",
        //   img: require("@/assets/img/produce/12.jpg"),
        // },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.watchGun);
    window.addEventListener("resize", this.inresize);
    this.inresize();
  },
  methods: {
    closeViews() {
      document.body.style.overflow = "";
      this.gwShow = false;
    },
    tabLogo(val) {
      document.body.style.overflow = "hidden";
      this.gwShow = true;
      this.viewImgs = val;
    },
    initScroll() {
      this.menus.forEach((item) => {
        if (!item.type) return;
        const dom = document.getElementsByClassName(item.type);
        let targetDom = dom[0].getBoundingClientRect();
        if (targetDom.top <= 0 && targetDom.bottom - 60 > 0) {
          this.menusIndex = item.type;
        }
      });
    },
    jumpMap(val) {
      const dom = document.getElementsByClassName(val.type)[0];
      const domTop = dom.offsetTop;
      window.scrollTo({
        top: domTop + 2,
        behavior: "smooth",
      });
    },
    inresize() {
      const wid =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (wid >= 992) {
        this.isMenu = true;
      } else {
        this.isMenu = false;
      }
    },
    watchGun(val) {
      // 滑动关闭menu
      this.inresize();
      const windowhei = window.innerHeight;
      const doms = document.getElementsByClassName("gw-flash-bg");
      const domInfo = doms[0].getBoundingClientRect();
      const domInfo1 = doms[1].getBoundingClientRect();
      const scrollYVal = window.scrollY;

      // const topVal =
      //   document.documentElement.scrollTop || document.body.scrollTop;

      this.initScroll(); // 滚动头部跟随效果
      if (domInfo.top - windowhei <= 0 && domInfo.bottom >= 0) {
        doms[0].style.backgroundPositionY = -scrollYVal + windowhei + "px";
      }
      if (domInfo1.top - windowhei <= 0 && domInfo1.bottom >= 0) {
        doms[1].style.backgroundPositionY =
          -scrollYVal + windowhei * 3.3 + "px";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
